@import url('../../../libs/theme/src/assets/theme.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
#google_translate_element .goog-te-gadget {
    .goog-te-gadget-simple{
        img {
            width: 0px;
            height: 0px;
        }
        span {
            display: ruby;
        }
        span a{
            pointer-events: none;
        }
    }
}

div.skiptranslate {
iframe.skiptranslate{
    visibility: hidden!important; 
}
} 